@tailwind base;
@tailwind components;
@tailwind utilities;

.ais-Breadcrumb-list,
.ais-CurrentRefinements-list,
.ais-HierarchicalMenu-list,
.ais-InfiniteHits-list,
.ais-InfiniteResults-list,
.ais-Menu-list,
.ais-NumericMenu-list,
.ais-Pagination-list,
.ais-RatingMenu-list,
.ais-RefinementList-list,
.ais-Results-list,
.ais-ToggleRefinement-list {
    @apply m-0 p-0;
    list-style: none;
}

.ais-Hits-list 
{
    @apply m-0 p-0 flex flex-wrap justify-center;
    list-style: none;
    
}

.ais-ClearRefinements-button,
.ais-CurrentRefinements-delete,
.ais-CurrentRefinements-reset,
.ais-HierarchicalMenu-showMore,
.ais-InfiniteHits-loadMore,
.ais-InfiniteResults-loadMore,
.ais-Menu-showMore,
.ais-RangeInput-submit,
.ais-RefinementList-showMore,
.ais-SearchBox-reset,
.ais-SearchBox-submit {
    @apply overflow-visible leading-[normal] text-inherit cursor-pointer select-none p-0 border-0;
    font: inherit;
    background: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.ais-ClearRefinements-button::-moz-focus-inner,
.ais-CurrentRefinements-delete::-moz-focus-inner,
.ais-CurrentRefinements-reset::-moz-focus-inner,
.ais-HierarchicalMenu-showMore::-moz-focus-inner,
.ais-InfiniteHits-loadMore::-moz-focus-inner,
.ais-InfiniteResults-loadMore::-moz-focus-inner,
.ais-Menu-showMore::-moz-focus-inner,
.ais-RangeInput-submit::-moz-focus-inner,
.ais-RefinementList-showMore::-moz-focus-inner,
.ais-SearchBox-reset::-moz-focus-inner,
.ais-SearchBox-submit::-moz-focus-inner {
    @apply p-0 border-0;
}

.ais-ClearRefinements-button[disabled],
.ais-CurrentRefinements-delete[disabled],
.ais-CurrentRefinements-reset[disabled],
.ais-HierarchicalMenu-showMore[disabled],
.ais-InfiniteHits-loadMore[disabled],
.ais-InfiniteResults-loadMore[disabled],
.ais-Menu-showMore[disabled],
.ais-RangeInput-submit[disabled],
.ais-RefinementList-showMore[disabled],
.ais-SearchBox-reset[disabled],
.ais-SearchBox-submit[disabled] {
    @apply cursor-default;
}

.ais-Breadcrumb-item,
.ais-Breadcrumb-list,
.ais-Pagination-list,
.ais-PoweredBy,
.ais-RangeInput-form,
.ais-RatingMenu-link {
    @apply flex items-center;
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-align: center;
    -ms-flex-align: center;
}

.ais-HierarchicalMenu-list .ais-HierarchicalMenu-list {
    @apply ml-[1em];
}

.ais-PoweredBy-logo {
    @apply block w-[70px] h-auto ml-[0.3rem];
}

.ais-RatingMenu-starIcon {
    @apply block w-5 h-5 relative w-[15px] fill-[#ffc168] -top-px;
}

.ais-SearchBox-input::-ms-clear,
.ais-SearchBox-input::-ms-reveal {
    @apply hidden w-0 h-0;
}

.ais-SearchBox-input::-webkit-search-cancel-button,
.ais-SearchBox-input::-webkit-search-decoration,
.ais-SearchBox-input::-webkit-search-results-button,
.ais-SearchBox-input::-webkit-search-results-decoration {
    @apply hidden;
}

.ais-RangeSlider .rheostat {
    @apply overflow-visible my-10;
}

.ais-RangeSlider .rheostat-background {
    @apply h-1.5 w-full top-0 relative bg-white border border-solid border-[#aaa] box-border border-[#878faf];
}

.ais-RangeSlider .rheostat-handle {
    @apply top-[-7px] -ml-3 border-[#878faf];
}

.ais-RangeSlider .rheostat-progress {
    @apply absolute h-1 bg-[#333] top-px bg-[#495588];
}

.rheostat-handle {
    @apply relative z-[1] w-5 h-5 bg-white border cursor-[-webkit-grab] cursor-grab rounded-[50%] border-solid border-[#333];
}

.rheostat-marker {
    @apply absolute w-px h-[5px] bg-[#aaa] -ml-px;
}

.rheostat-marker--large {
    @apply h-[9px];
}

.rheostat-value {
    @apply pt-[15px];
}

.rheostat-tooltip,
.rheostat-value {
    @apply absolute text-center -translate-x-2/4 ml-[50%];
    -webkit-transform: translateX(-50%);
}

.rheostat-tooltip {
    @apply top-[-22px];
}

[class^="ais-"] {
    @apply text-base box-border;
}

a[class^="ais-"] {
    @apply no-underline;
}

.ais-Breadcrumb,
.ais-ClearRefinements,
.ais-CurrentRefinements,
.ais-HierarchicalMenu,
.ais-Hits,
.ais-HitsPerPage,
.ais-InfiniteHits,
.ais-InfiniteResults,
.ais-Menu,
.ais-MenuSelect,
.ais-NumericMenu,
.ais-NumericSelector,
.ais-Pagination,
.ais-Panel,
.ais-PoweredBy,
.ais-RangeInput,
.ais-RangeSlider,
.ais-RatingMenu,
.ais-RefinementList,
.ais-Results,
.ais-ResultsPerPage,
.ais-SearchBox,
.ais-SortBy,
.ais-Stats,
.ais-ToggleRefinement {
    @apply text-[#3a4570];
}

.ais-Breadcrumb-item--selected,
.ais-HierarchicalMenu-item--selected,
.ais-Menu-item--selected {
    @apply font-bold;
}

.ais-Breadcrumb-separator {
    @apply font-normal mx-[0.3em] my-0;
}

.ais-Breadcrumb-link,
.ais-HierarchicalMenu-link,
.ais-Menu-link,
.ais-Pagination-link,
.ais-RatingMenu-link {
    @apply text-[#0096db] transition-[color] duration-[0.2s] ease-[ease-out];
}

.ais-Breadcrumb-link:focus,
.ais-Breadcrumb-link:hover,
.ais-HierarchicalMenu-link:focus,
.ais-HierarchicalMenu-link:hover,
.ais-Menu-link:focus,
.ais-Menu-link:hover,
.ais-Pagination-link:focus,
.ais-Pagination-link:hover,
.ais-RatingMenu-link:focus,
.ais-RatingMenu-link:hover {
    @apply text-[#0073a8];
}

.ais-ClearRefinements-button,
.ais-CurrentRefinements-reset,
.ais-HierarchicalMenu-showMore,
.ais-InfiniteHits-loadMore,
.ais-InfiniteResults-loadMore,
.ais-Menu-showMore,
.ais-RefinementList-showMore {
    @apply text-[0.8rem] text-white bg-[#0096db] transition-[background-color] duration-[0.2s] ease-[ease-out] px-2 py-[0.3rem] rounded-[5px];
    outline: none;
}

.ais-ClearRefinements-button:focus,
.ais-ClearRefinements-button:hover,
.ais-CurrentRefinements-reset:focus,
.ais-CurrentRefinements-reset:hover,
.ais-HierarchicalMenu-showMore:focus,
.ais-HierarchicalMenu-showMore:hover,
.ais-InfiniteHits-loadMore:focus,
.ais-InfiniteHits-loadMore:hover,
.ais-InfiniteResults-loadMore:focus,
.ais-InfiniteResults-loadMore:hover,
.ais-Menu-showMore:focus,
.ais-Menu-showMore:hover,
.ais-RefinementList-showMore:focus,
.ais-RefinementList-showMore:hover {
    @apply bg-[#0073a8];
}

.ais-ClearRefinements-button--disabled,
.ais-HierarchicalMenu-showMore--disabled,
.ais-InfiniteHits-loadMore--disabled,
.ais-InfiniteResults-loadMore--disabled,
.ais-Menu-showMore--disabled,
.ais-RefinementList-showMore--disabled {
    @apply opacity-60 cursor-not-allowed;
}

.ais-ClearRefinements-button--disabled:focus,
.ais-ClearRefinements-button--disabled:hover,
.ais-HierarchicalMenu-showMore--disabled:focus,
.ais-HierarchicalMenu-showMore--disabled:hover,
.ais-InfiniteHits-loadMore--disabled:focus,
.ais-InfiniteHits-loadMore--disabled:hover,
.ais-InfiniteResults-loadMore--disabled:focus,
.ais-InfiniteResults-loadMore--disabled:hover,
.ais-Menu-showMore--disabled:focus,
.ais-Menu-showMore--disabled:hover,
.ais-RefinementList-showMore--disabled:focus,
.ais-RefinementList-showMore--disabled:hover {
    @apply bg-[#0096db];
}

.ais-CurrentRefinements {
    @apply mt-[-0.3rem];
}

.ais-CurrentRefinements,
.ais-CurrentRefinements-list {
    @apply flex flex-wrap;
    display: -webkit-box;
    display: -ms-flexbox;
    -ms-flex-wrap: wrap;
}

.ais-CurrentRefinements-item {
    @apply flex gap-4 bg-[#495588] mr-[0.3rem] mt-[0.3rem] px-2 py-[0.3rem] rounded-[5px];
    display: -webkit-box;
    display: -ms-flexbox;
}

.ais-CurrentRefinements-category {
    @apply flex ml-[0.3em];
    display: -webkit-box;
    display: -ms-flexbox;
}

.ais-CurrentRefinements-delete {
    @apply ml-[0.3rem];
}

.ais-CurrentRefinements-categoryLabel,
.ais-CurrentRefinements-delete,
.ais-CurrentRefinements-label {
    @apply whitespace-nowrap text-[0.8rem] text-white;
}

.ais-CurrentRefinements-reset {
    @apply whitespace-nowrap mt-[0.3rem];
}

.ais-CurrentRefinements-reset+.ais-CurrentRefinements-list {
    @apply ml-[0.3rem];
}

.ais-HierarchicalMenu-link,
.ais-Menu-link {
    @apply block leading-normal;
}

.ais-HierarchicalMenu-list,
.ais-Menu-list,
.ais-NumericMenu-list,
.ais-RatingMenu-list,
.ais-RefinementList-list {
    @apply font-normal leading-normal;
}

.ais-HierarchicalMenu-link:after {
    @apply content-[""] w-2.5 h-2.5 hidden bg-[url("data:image/svg+xml;charset=utf-8,%3Csvg_xmlns='http://www.w3.org/2000/svg'_viewBox='0_0_24_24'%3E%3Cpath_d='M7.33_24L4.5_21.171l9.339-9.175L4.5_2.829_7.33_0_19.5_11.996z'_fill='%233A4570'/%3E%3C/svg%3E")] bg-[100%_100%] ml-[0.3em];
}

.ais-HierarchicalMenu-item--parent>.ais-HierarchicalMenu-link:after {
    @apply inline-block;
}

.ais-HierarchicalMenu-item--selected>.ais-HierarchicalMenu-link:after {
    @apply rotate-90;
    -webkit-transform: rotate(90deg);
}

.ais-CurrentRefinements-count,
.ais-RatingMenu-count {
    @apply text-[0.8rem];
}

.ais-CurrentRefinements-count:before,
.ais-RatingMenu-count:before {
    @apply content-["("];
}

.ais-CurrentRefinements-count:after,
.ais-RatingMenu-count:after {
    @apply content-[")"];
}

.ais-HierarchicalMenu-count,
.ais-Menu-count,
.ais-RefinementList-count,
.ais-ToggleRefinement-count {
    @apply text-[0.8rem] text-[#3a4570] bg-[#dfe2ee] px-[0.4rem] py-[0.1rem] rounded-lg;
}

.ais-HierarchicalMenu-showMore,
.ais-Menu-showMore,
.ais-RefinementList-showMore {
    @apply mt-2;
}

.ais-Highlight-highlighted,
.ais-Snippet-highlighted {
    @apply bg-[#ffc168];
}

.ais-Hits-list,
.ais-InfiniteHits-list,
.ais-InfiniteResults-list,
.ais-Results-list {
    @apply mt-[-1rem] ml-[-1rem] flex flex-wrap;
    -ms-flex-wrap: wrap;
}

.ais-Panel-body .ais-Hits-list,
.ais-Panel-body .ais-InfiniteHits-list,
.ais-Panel-body .ais-InfiniteResults-list,
.ais-Panel-body .ais-Results-list {
    @apply ml-[-1rem] mr-0 mt-2 mb-0;
}

.ais-Hits-item,
.ais-InfiniteHits-item,
.ais-InfiniteResults-item,
.ais-Results-item {
    @apply flex flex-wrap w-[calc(40%_-_1rem)] border shadow-[0_2px_5px_0_#dbdff0] ml-4 mt-4 p-4 rounded-[5%] border-solid border-[#aebaed];
}

.ais-Panel-body .ais-Hits-item,
.ais-Panel-body .ais-InfiniteHits-item,
.ais-Panel-body .ais-InfiniteResults-item,
.ais-Panel-body .ais-Results-item {
    @apply ml-4 mr-0 my-2 ;
}

.ais-InfiniteHits-loadMore,
.ais-InfiniteResults-loadMore {
    @apply mt-4;
}

.ais-HitsPerPage-select,
.ais-MenuSelect-select,
.ais-NumericSelector-select,
.ais-ResultsPerPage-select,
.ais-SortBy-select {
    @apply appearance-none bg-white bg-[url("data:image/svg+xml;charset=utf-8,%3Csvg_xmlns='http://www.w3.org/2000/svg'_viewBox='0_0_24_24'%3E%3Cpath_d='M0_7.33L2.829_4.5l9.175_9.339L21.171_4.5_24_7.33_12.004_19.5z'_fill='%233A4570'/%3E%3C/svg%3E")] bg-no-repeat bg-[10px_10px] bg-[92%_50%] border pl-[0.3rem] pr-8 py-[0.3rem] rounded-[5px] border-solid border-[#c4c8d8];
    -webkit-appearance: none;
    -moz-appearance: none;
}

.ais-Panel-header {
    @apply text-[0.8rem] font-bold uppercase mb-2 pb-2 border-b-[#c4c8d8] border-b border-solid;
}

.ais-Panel-footer {
    @apply text-[0.8rem] mt-2;
}

.ais-RangeInput-input {
    @apply w-20 h-6 leading-6 px-[0.2rem] py-0;
}

.ais-RangeInput-separator {
    @apply mx-[0.3rem] my-0;
}

.ais-RangeInput-submit {
    @apply appearance-none h-6 leading-6 text-[0.8rem] text-white bg-[#0096db] transition-[0.2s] duration-[ease-out] ml-[0.3rem] px-2 py-0 rounded-[5px] border-[none];
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
}

.ais-RangeInput-submit:focus,
.ais-RangeInput-submit:hover {
    @apply bg-[#0073a8];
}

.ais-RatingMenu-count {
    @apply text-[#3a4570];
}

.ais-Pagination-list {
    @apply justify-center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
}

.ais-Pagination-item+.ais-Pagination-item {
    @apply ml-[0.3rem];
}

.ais-Pagination-link {
    @apply block border transition-[background-color] duration-[0.2s] ease-[ease-out] px-[0.6rem] py-[0.3rem] rounded-[5px] border-solid border-[#c4c8d8];
}

.ais-Pagination-link:focus,
.ais-Pagination-link:hover {
    @apply bg-[#e3e5ec];
}

.ais-Pagination-item--disabled .ais-Pagination-link {
    @apply opacity-60 cursor-not-allowed text-[#a5abc4];
}

.ais-Pagination-item--disabled .ais-Pagination-link:focus,
.ais-Pagination-item--disabled .ais-Pagination-link:hover {
    @apply text-[#a5abc4] bg-white;
}

.ais-Pagination-item--selected .ais-Pagination-link {
    @apply text-white bg-[#0096db] border-[#0096db];
}

.ais-Pagination-item--selected .ais-Pagination-link:focus,
.ais-Pagination-item--selected .ais-Pagination-link:hover {
    @apply text-white;
}

.ais-PoweredBy-text,
.ais-Stats-text,
.rheostat-tooltip,
.rheostat-value {
    @apply text-[0.8rem];
}

.ais-RangeSlider .rheostat-marker {
    @apply bg-[#878faf];
}

.ais-Panel-body .ais-RangeSlider {
    @apply mx-0 my-8;
}

.ais-RatingMenu-item--disabled .ais-RatingMenu-count,
.ais-RatingMenu-item--disabled .ais-RatingMenu-label {
    @apply text-[#c4c8d8];
}

.ais-RatingMenu-item--selected {
    @apply font-bold;
}

.ais-RatingMenu-link {
    @apply leading-normal;
}

.ais-RatingMenu-link>*+* {
    @apply ml-[0.3rem];
}

.ais-RatingMenu-item--disabled .ais-RatingMenu-starIcon {
    @apply fill-[#c4c8d8];
}

.ais-HierarchicalMenu-searchBox>*,
.ais-Menu-searchBox>*,
.ais-RefinementList-searchBox>* {
    @apply mb-2;
}

.ais-SearchBox-form {
    @apply block relative;
}

.ais-SearchBox-input {
    @apply appearance-none w-full relative bg-white border px-[1.7rem] py-[0.3rem] rounded-[5px] border-solid border-[#c4c8d8];
    -webkit-appearance: none;
    -moz-appearance: none;
}

.ais-SearchBox-input::-webkit-input-placeholder {
    @apply text-[#a5aed1];
}

.ais-SearchBox-input::-moz-placeholder {
    @apply text-[#a5aed1];
}

.ais-SearchBox-input:-ms-input-placeholder {
    @apply text-[#a5aed1];
}

.ais-SearchBox-input:-moz-placeholder {
    @apply text-[#a5aed1];
}

.ais-SearchBox-loadingIndicator,
.ais-SearchBox-reset,
.ais-SearchBox-submit {
    @apply appearance-none absolute z-[1] w-5 h-5 -translate-y-2/4 right-[0.3rem] top-2/4;
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-transform: translateY(-50%);
}

.ais-SearchBox-submit {
    @apply left-[0.3rem];
}

.ais-SearchBox-reset {
    @apply right-[0.3rem];
}

.ais-SearchBox-loadingIcon,
.ais-SearchBox-resetIcon,
.ais-SearchBox-submitIcon {
    @apply absolute -translate-x-2/4 -translate-y-2/4 left-2/4 top-2/4;
    -webkit-transform: translateX(-50%) translateY(-50%);
}

.ais-SearchBox-resetIcon path,
.ais-SearchBox-submitIcon path {
    @apply fill-[#495588];
}

.ais-SearchBox-submitIcon {
    @apply w-3.5 h-3.5;
}

.ais-SearchBox-resetIcon {
    @apply w-3 h-3;
}

.ais-SearchBox-loadingIcon {
    @apply w-4 h-4;
}